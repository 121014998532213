export const testimonialsSlider = ()=> {
    $('.testimonials__slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        mobileFirst: true,
        speed: 500,
        dots: true,
        fade: true,
        arrows: false,
    })
}